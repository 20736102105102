import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';

export default ({ title, children }) => {
    const [isActive, setIsActive] = useState(false);

    return <article>
        <button className="w-full text-left text-lg text-white" onClick={() => setIsActive(!isActive)}>
            {isActive ? <FaChevronDown className='icon-sm'></FaChevronDown> : <FaChevronRight className='icon-sm'></FaChevronRight>} {title instanceof Function ? title() : title}
        </button>
        {isActive && children}
    </article>
};
